// ================================================================================
// script.js
// 案件用のスクリプトファイル
// ================================================================================

// DOM Read for Vanilla
// --------------------------------------------------------------- //

// document.addEventListener('DOMContentLoaded', () => {

// 	console.log('Hello!');

// } );

// Device Check
// --------------------------------------------------------------- //
let device;

function check_device() {
	const breakpoint_m = 1215;
	const breakpoint_s = 960;
	const $window = $(window);
	let window_width = parseInt($window.width());
	if (window_width <= breakpoint_m && window_width > breakpoint_s) {
		device = 'tab';
	} else if (window_width <= breakpoint_s) {
		device = 'sp';
	} else {
		device = 'pc';
	}
}
check_device();
// console.log(device);


$(function () {
	const $window = $(window),
		$html = $('html'),
		$body = $('body'),
		$doc = $('html, body');
	let current_scrollYsm;


	// SP Menu
	// --------------------------------------------------------------- //
	$(function () {
		let $button = $('.js-menu-button');
		let $target = $('.js-header');
		let class_name = 'site-header--active';
		$button.click(function (e) {
			if (!$target.hasClass(class_name)) {
				// menu open
				$target.addClass(class_name);
				current_scrollYsm = $window.scrollTop();
				$body.css({
					position: 'fixed',
					width: '100%',
					top: -1 * current_scrollYsm
				}).addClass('is-fixed');
			} else {
				// menu close
				$target.removeClass(class_name);
				$body.attr({
					style: ''
				}).removeClass('is-fixed');
				$(window).scrollTop(current_scrollYsm);
			}
			e.preventDefault();
		});
	});


	// Header 1199px以下の時はヘッダーを横スクロールで表示
	// --------------------------------------------------------------- //
	$(window).on('load resize', function () {
		let $header = $('.js-header');
		let scroll_left;
		if ($window.innerWidth() <= 1199) {
			$window.on('scroll', function () {
				scroll_left = $(this).scrollLeft();
				$header.css({
					left: -scroll_left
				});
			});
		}
	});


	// Header Scroll
	// --------------------------------------------------------------- //

	let startPos = 0;
	let $header = $('.js-header');
	let triggerPos = 40;

	// init
	let scrollTop = window.pageYOffset;

	if ( scrollTop < triggerPos ) {
		$header.removeClass('is-hidden');
		$header.removeClass('has-animation');
	} else {
		$header.addClass('has-animation');
	}


	window.addEventListener('scroll', function(e) {
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		let currentPos = scrollTop;

		if (currentPos > startPos) {
			// down
			if ( scrollTop >= triggerPos ) {
				$header.addClass('is-hidden');
				$header.addClass('has-animation');
			}
		} else {
			// up
			$header.removeClass('is-hidden');
			if ( scrollTop < triggerPos ) {
				$header.removeClass('has-animation');
			} else {
				$header.addClass('has-animation');
			}
		}
		startPos = currentPos;
	});


	// Accordion
	// --------------------------------------------------------------- //

	// Footer
	$(function () {
		let $button = $('.js-footer-accordion > a');
		let class_name = 'footer-menu-item--open';

		if (device == 'sp') {
			$button.on('click', function () {
				$(this).closest('.footer-menu-item').toggleClass(class_name);
				$(this).nextAll('.footer-menu-child').stop().slideToggle('fast');
				return false;
			});
		}
	});

	// Shop
	$(function () {
		let $button = $('.js-shop-accordion');
		let class_name = 'shop-archive-list-title--open';
		let target = $('.shop-archive-list-content');

		target.css('display', 'none');

		$button.on('click', function () {
			$(this).toggleClass(class_name);
			$(this).next().stop().slideToggle('fast');
			return false;
		});
	});


	// Page Top
	// --------------------------------------------------------------- //
	$('.js-page-top').click(function () {
		$('body, html').animate({
			scrollTop: 0
		}, 500);

		return false;
	});


	// Floating Button
	// --------------------------------------------------------------- //

	$(window).on('load resize scroll', function () {
		var $scroll = $(this).scrollTop();
		var $target = $('#js-floating');
		var $footer = $('.site-footer').offset().top;
		var $windowHeight = $(window).height();

		if ( !$('.wpcf7').length ) {
			if ( $scroll > 50 && $scroll < ($footer - $windowHeight) ) {
				$target.fadeIn('fast');

			} else {
				$target.fadeOut('fast');
			}
		}

	});





	// Smooth Scroll
	// --------------------------------------------------------------- //
	let smooth_scroll = function () {
		let $header = $('.js-header');
		let header_height = $header.height();
		let urlHash = location.hash;
		let animeSpeed = 500;

		if (urlHash) {
			$('html, body').stop().scrollTop(0);
			setTimeout(function () {
				let target = $(urlHash);
				let position = target.offset().top - header_height;
				$('html, body').stop().animate({ scrollTop: position }, animeSpeed);
			}, 100);
		}

		let $btn = $('a[href*="#"]').not('');
		$btn.click(function () {
			let speed = 500;
			let href = $(this).attr("href");
			href = href.split("#")[1];
			href = "#" + href;
			let target = $(href == "#" || href == "" ? 'html' : href);
			let position = target.offset().top - header_height;

			$("html, body").animate({ scrollTop: position }, speed, "swing");

			// ハンバーガーメニューがアクティブのとき
			// if ($header.hasClass('is-header-navi-open')) {
			// 	$header.removeClass('is-header-navi-open');
			// 	$body.attr({
			// 		style: ''
			// 	}).removeClass('is-fixed');
			// 	$(window).scrollTop(current_scrollYsm);
			// 	position = target.offset().top - header_height;
			// 	$("html, body").animate({ scrollTop: position }, speed, "swing");
			// } else {
			// 	$("html, body").animate({ scrollTop: position }, speed, "swing");
			// }
			return false;
		});
	}
	smooth_scroll();

});


// load
// --------------------------------------------------------------- //

// Page Top Position
$(window).on('load', function () {

//	var $this = $('.js-page-top');
//	var $contents_fin = $('.site-footer').offset().top - $(window).height();
//
//	$(window).scroll(function () {
//		var $win = $(window).scrollTop();
//
//		if ($win > 200) {
//			$this.addClass('page-top--active');
//		} else {
//			$this.removeClass('page-top--active');
//		}
//
//		if ($win > $contents_fin) {
//			$this.removeClass('page-top--fixed');
//		} else {
//			$this.addClass('page-top--fixed');
//		}
//
//	});

});